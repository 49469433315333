@import "scss/reset";
@import "scss/base";

$primaryColor: white;
$secondaryColor: #f3d971;
$background: black;

@mixin titles($size) {
  font-size: $size;
  font-family: "Montserrat";
  font-weight: 900;
  text-transform: uppercase;
  letter-spacing: 4px;
}

@mixin hoverEffet {
  width: fit-content;
  list-style: none;
  overflow-x: hidden;
  height: 30px;

  &::after {
    content: "";
    display: block;
    width: 0%;
    height: 2px;
    margin: auto;
    background: $secondaryColor;
    transition: all 0.3s ease-in;
  }

  &:hover::after {
    width: 100%;
  }
}

body::-webkit-scrollbar {
  display: none;
}

body {
  -ms-overflow-style: none;
  scrollbar-width: none;
  background: $background;
  color: $primaryColor;
  overflow: hidden;
  display: grid;
  grid-template-columns: auto 0px;
  letter-spacing: 2px;
  height: 100vh;
}

.transition {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: fixed;
  z-index: 2;

  div {
    width: 33.33%;
    height: 100vh;
    background-color: $secondaryColor;
  }
}

nav {
  display: flex;
  justify-content: space-between;
  padding: 1rem 2rem;
  font-weight: 600;
  height: 60px;
  color: $secondaryColor;
  font-size: 1.3rem;
  text-transform: uppercase;

  .name {
    font-weight: bold;
  }

  ul {
    display: flex;
    margin-top: 20px;

    li {
      @include hoverEffet;
      padding: 0 5vw;

      a {
        color: $primaryColor;
      }
    }
  }
}

header {
  height: calc(100vh - 60px);
  display: flex;
  justify-content: center;
  align-items: center;

  .wrap {
    overflow: hidden;
    h2 {
      @include titles(7rem);
      text-align: center;

      transform: translateY(0px);
      animation: displayTitle 2s ease-in;
    }
  }
}

#project {
  display: flex;
  justify-content: space-between;
  h2 {
    margin-bottom: 10rem;
  }
}

section {
  padding: 1rem 2rem;
  min-height: 100vh;
  h2 {
    @include titles(3rem);
    color: $secondaryColor;
  }

  .projects {
    padding: 0 2rem;
    position: relative;

    .project {
      display: none;
      & > div {
        overflow: hidden;
      }

      .wrap_title {
        margin-bottom: 3rem;
        h3 {
          @include titles(2rem);
          animation: displayDescription 0.5s ease-in;
          position: relative;
          display: flex;
          width: fit-content;
          
          &:after {
            content: "";
            position: absolute;
            right: -16px;
            bottom: 7px;
            display: block;
            width: 12px;
            height: 12px;
            border-radius: 50%;
            background: $secondaryColor;
          }
        }
      }

      .wrap_description {
        p {
          animation: displayDescription 0.5s ease-in;
          font-size: 1.3rem;
          margin-bottom: 1.3rem;
        }
      }

      .wrap_link {
        margin-top: 30px;
        p {
          font-size: 1.3rem;
          animation: displayDescription 0.5s ease-in;
        }
      }
    }

    .controls {
      display: flex;

      button {
        padding: 20px;
        color: $secondaryColor;
        font-size: 2rem;
        transform: scaleX(1.5);

        svg {
          width: 30px;
        }
      }
    }
  }
}

#about {
  p:first-of-type {
    margin-bottom: 30px;
  }
  p {
    text-align: center;
    font-size: 2rem;
    max-width: 80vw;
    margin: 0 auto;
    margin-bottom: 2rem;
    line-height: 1.2;
    letter-spacing: 2px;
  }
}

.flex {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 90vh;
}

#contact {
  position: relative;

  .mail {
    font-size: 3rem;
  }

  ul {
    position: absolute;
    bottom: 20px;
    display: flex;
    font-size: 1.3rem;
    text-transform: uppercase;

    li {
      padding: 0 5vw;
      font-weight: 600;
      @include hoverEffet;
    }
  }
}

#topButton {
  place-self: end;
  position: sticky;
  margin-top: 150vh;
  bottom: 2rem;
  right: 2rem;
  display: grid;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;

  color: white;
  background-color: $secondaryColor;
  font-size: 2rem;

  transform: translateY(0%);
  transition: all 0.2s;
  cursor: pointer;

  svg {
    width: 24px;
  }
}

#topButton:hover {
  transform: translateY(-20px);
}

@keyframes displayDescription {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes displayTitle {
  0% {
    transform: translateY(100%);
  }
  70% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}

.link {
  a {
    overflow: hidden;
    padding: 0 4px;
    height: 1em;
    position: relative;
    display: inline-block;
    outline: none;
    letter-spacing: 1px;
    font-weight: 400;
    text-shadow: 0 0 1px rgba(255, 255, 255, 0.3);

    &:hover,
    &:focus {
      span {
        -webkit-transform: translateY(-100%);
        -moz-transform: translateY(-100%);
        transform: translateY(-100%);
      }
    }

    span {
      position: relative;
      display: inline-block;
      -webkit-transition: -webkit-transform 0.3s;
      -moz-transition: -moz-transform 0.3s;
      transition: transform 0.3s;

      &::before {
        position: absolute;
        top: 100%;
        content: attr(data-hover);
        color: $secondaryColor;
        -webkit-transform: translate3d(0, 0, 0);
        -moz-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
      }
    }
  }
}

@media screen and (max-width: 1100px) {
  #project {
    h2 {
      margin-bottom: 2rem;
    }
  }
}

@media screen and (max-width: 1200px) {
  #project {
    flex-direction: column;

    canvas {
      margin: auto;
    }
  }
}
@media screen and (max-width: 850px) {
  nav {
    position: relative;

    ul {
      position: absolute;
      bottom: -20px;
      width: calc(100% - 2rem);
      justify-content: center;
    }
  }

  header {
    .wrap {
      h2 {
        @include titles(5rem);
      }
    }
  }

  #tidio-chat-iframe {
    inset: auto 2px 55px auto !important;
  }
}

@media screen and (max-width: 700px) {
  html {
    font-size: 16px;
  }
  nav {
    padding: 1rem;

    ul {
      bottom: -50px;
    }
  }

  header {
    .wrap {
      h2 {
        @include titles(3rem);
      }
    }
  }

  #contact {
    .mail {
      font-size: 2rem;
    }

    ul {
      position: absolute;
      bottom: 5px;
      font-size: 1rem;

      li {
        padding: 0 2vw;
      }
    }
  }
}

@media screen and (max-width: 530px) {
  html {
    font-size: 12px;
  }
  header {
    .wrap {
      h2 {
        @include titles(3rem);
      }
    }
  }
}

@media screen and (max-width: 400px) {
  html {
    font-size: 10px;
  }
  header {
    .wrap {
      h2 {
        @include titles(2rem);
      }
    }
  }

  #project {
    flex-direction: column;
    min-height: 60vh;

    canvas {
      display: none;
    }
  }
}
