@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;900&display=swap");

body {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
}

h1,
h2,
h3,
h4 {
  font-family: "Tenor Sans", sans-serif;
}
